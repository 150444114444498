<template>
	<div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="处理中心" name="first">
				<div class="padding16 ">
					<el-upload class="upload-demo" drag action="https://jsonplaceholder.typicode.com/posts/" multiple>
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>

					</el-upload>
					<el-tooltip class="item" effect="light" placement="top-start">
						<div slot="content" style="font-size: 14px;width:400px">1.支持上传 cdr,jpg,pdf 格式文件(请尽量上传源文件)，且每个文件不超过800M。
							<br />2.pdf和ai格式文件建议上传的时候同时上传一个jpg格式文件方便对稿。
							<br />3.cdr或ai格式文件必须转曲线，色值设计cmyk色值；有效内容距成品线至少两毫米。
							<br />4.jpg格式文件色值设计要求cmyk色值，分辨率三百以上，有效内容距成品线至少两毫米。
							<br />5.提供文件图片尺寸大小要和填写的尺寸保持一致，所上传文件会由接单员复核，请确保所上传文件符合规范。
							<br />6.提交后需要替换文件或修改订单，请单独联系接单员。
							<br />7.为确保能顺利上传文件，请使用谷歌、火狐或QQ、360浏览器的极速模式。
						</div>
						<el-button type="text">文件注意事项</el-button>
					</el-tooltip>
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
						<el-form-item label="传稿人电话：" prop="phone">
							<el-input v-model="ruleForm.phone" placeholder="为方便有问题快速核对，请留下传稿人电话"></el-input>
						</el-form-item>
						<el-form-item label="下单要求：" prop="ask">
							<el-input v-model="ruleForm.ask" placeholder="材料、尺寸、数量请务必备注清楚，若需代送或者发快递请备注地址（省市区街道路+收件人+电话），不备注默认是注册地址"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('ruleForm')">提交文件</el-button>
						</el-form-item>
					</el-form>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
export default {
	data: () => {
		return {
			activeName: 'first',
			ruleForm: {
				phone: '',
				ask: ''
			},
			rules: {
				phone: [
					{ required: true, message: '传稿人电话必须填写', trigger: 'blur' },
					{  max: 11, message: '长度11个数字内', trigger: 'blur' }
				],
				ask: [
					{ required: true, message: '材料、尺寸、数量请务必备注清楚', trigger: 'blur' }
				],
			}
		}
	},
	mounted() {
		console.log(this.$route, 'rrr');
	},
	methods: {
		handleClick() {

		}
	}
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__item {
	position: relative;
	font-size: 16px;
	padding: 0 24px !important;
}

::v-deep .el-tabs__content {
	padding: 8px;
	width: 100%;
	height: 100%;
}


::v-deep .el-upload {
	width: 100%;

}

::v-deep .el-upload-dragger {
	width: 100%;
	height: 360px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

::v-deep .el-upload-dragger .el-icon-upload {
	margin: 0;
}

.padding16 {
	padding: 16px;
	width: 100%;
	box-sizing: border-box;
}
</style>